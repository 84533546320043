<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select v-model="query.type" @change="getList(1)" clearable>
        
          <el-option v-for="(item,index) in this.const.PRODUCT_PAUSE_TYPE" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>

      <el-table-column label="用户名" prop="userId">
        <template #default="scope">
          <span>{{ scope.row.userRealName || scope.row.nickName }}</span>
        </template>
      </el-table-column>

      <el-table-column label="申请暂停日期" prop="createAt"> </el-table-column>
      
      <el-table-column label="套餐剩余时间" prop="originalMillisecond">
        <template #default="scope">
          <span>{{this.util.formatDay(scope.row.originalMillisecond / 1000)}}</span>
        </template>
      </el-table-column>

      <el-table-column label="最多暂停天数" prop="pauseDay"></el-table-column>


      <el-table-column label="状态" prop="type">
        <template #default="scope">
          <eb-badge
            :list="this.const.PRODUCT_PAUSE_TYPE"
            :target="scope.row.type"
          ></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="冻结电池" prop="macId"></el-table-column>

      <el-table-column label="恢复日期" prop="afreshTime"> </el-table-column>
      <!-- <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="doDetail(scope.row.id, false)"
            >详情</span
          >

          <span class="option option-danger">禁用</span>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { fetchPauseList } from "@/api/batteryMan";
export default {
  data() {
    return {
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10
      },
      query: {
       
      },
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchPauseList({ ...this.query, ...this.pages }).then(
        (res) => {
          let list = res.data.data.records || [];
          this.total = res.data.data.total;
          this.dataSource = list;
        }
      );
    },
  },
};
</script>
